let isSearchBoxVisibleOnMobile = false;
const searchBoxVisibilityClass = 'search-box-visible';

window['addSearchBoxPlaceholder'] = () => {
  const timeoutMs = 1000;
  const fn = () => {
    const input = document.querySelector('.gsc-control-cse input.gsc-input');
    input.setAttribute('placeholder', 'Search');
  };
  setTimeout(fn, timeoutMs);
}

window['toggleSearchBox'] = ()=> {
  isSearchBoxVisibleOnMobile = !isSearchBoxVisibleOnMobile;
  if (isSearchBoxVisibleOnMobile) {
    document.body.classList.add(searchBoxVisibilityClass);
  } else {
    document.body.classList.remove(searchBoxVisibilityClass);
  }
}